<template>
  <div class="index">
    <div class="chat-list">
      <div class="nav-title">发蜘客</div>
      <div class="chat-list-user">
        <div
          v-for="(item, index) in chatList"
          :key="index"
          @click="lookChatRecords(index)"
          :class="current == index ? 'active-background' : 'user-info'"
        >
          <div class="expression-steps">
            <p>{{ item.name }}</p>
            <p v-if="item.num">{{ item.num }}</p>
          </div>
          <div v-if="item.name == '垃圾箱'" class="empty" @click.stop="handleEmpty">
            [清空]
          </div>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <edit-detail
      v-if="current == 0"
      @draftsUpdateTotal="draftsUpdateTotal"
      @editChangeCurrent="editChangeCurrent"
      @alreadyUpdateTotal="alreadyUpdateTotal"
      ref="editDetail"
    ></edit-detail>
    <!-- 草稿箱 -->
    <drafts-detail
      v-if="current == 1"
      @draftsUpdateTotal="draftsUpdateTotal"
      @editArticl="editArticl"
      @dustbinUpdateTotal="dustbinUpdateTotal"
    ></drafts-detail>
    <!-- 已发稿件 -->
    <already-sent-manuscript
      v-if="current == 2"
      @alreadyUpdateTotal="alreadyUpdateTotal"
      @editArticl="editArticl"
      @dustbinUpdateTotal="dustbinUpdateTotal"
    ></already-sent-manuscript>
    <!-- 垃圾箱 -->
    <dustbin-detail
      :clearStatus="clearStatus"
      v-if="current == 3"
      ref="dustbin"
      @dustbinUpdateTotal="dustbinUpdateTotal"
      @draftsUpdateTotal="draftsUpdateTotal"
      @alreadyUpdateTotal="alreadyUpdateTotal"
    ></dustbin-detail>
  </div>
</template>

<script>
import editDetail from "./components/editDetail";
import draftsDetail from "./components/draftsDetail";
import alreadySentManuscript from "./components/alreadySentManuscript";
import dustbinDetail from "./components/dustbinDetail";
import articleApi from "@/api/apiModel/article";
export default {
  name: "uploadExpression",
  components: {
    editDetail,
    draftsDetail,
    alreadySentManuscript,
    dustbinDetail,
  },
  data() {
    return {
      current: 0,
      isShow: true,
      // clearStatus:true, //清空的状态 刷新垃圾箱子组件
      clearStatus:0, //每次清空就传给子组件 子组件监听变化就掉数据列表的接口
      chatList: [
        { name: "编辑" },
        { name: "草稿箱", num: '' },
        { name: "已发稿件", num: '' },
        { name: "垃圾箱", num: '' },
      ],
    };
  },
  created() {
    this.getArticleList(); //初始化数量
  },
  methods: {
    // 查看对应用户聊天记录
    lookChatRecords(index) {
      this.current = index;
      this.isShow = false;
    },
    // 清空
    handleEmpty() {
       articleApi.clearRubbish().then(res=>{
          //刷新组件的方法会闪烁 
          // this.clearStatus = false
          // setTimeout(()=>{
          //    this.clearStatus = true
          // },100)
          this.clearStatus++ //改变数据 传给子组件
          this.$message({
              message: '清空成功',
              type: 'success'
        });
        this.getArticleList()
      })
      // this.$refs.dustbin.clearEmpty()
    },
    //初始化数量
    getArticleList() {
      // status 0 草稿 1 发布
      articleApi
        .articleList({ status: 1 })
        .then((res) => {
          // console.log("文章列表",res.result.total)
          this.chatList[2].num = res.result.total;
        })
        .catch((err) => {
          console.log(err);
        });
      // status 0 草稿 1 发布
      articleApi
        .articleList({ status: 0 })
        .then((res) => {
          // console.log("文章列表",res.result.total)
          this.chatList[1].num = res.result.total;
        })
        .catch((err) => {
          console.log(err);
        });
      //垃圾箱数量
      articleApi
        .rubbishList()
        .then((res) => {
          // console.log("垃圾箱列表",res.result.total)
          this.chatList[3].num = res.result.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //已发稿件的总数更新
    alreadyUpdateTotal(total) {
      this.$store.dispatch("changeNum", "发蜘客");
      this.chatList[2].num = total;
    },
    //草稿箱的总数更新
    draftsUpdateTotal(total) {
      this.$store.dispatch("changeNum", "发蜘客");
      this.chatList[1].num = total;
    },
    //垃圾箱的总数更新
    dustbinUpdateTotal(total) {
      this.$store.dispatch("changeNum", "发蜘客");
      this.chatList[3].num = total;
      this.getArticleList()
    },

    //修改文章
    editArticl(item) {
      this.current = 0;
      setTimeout(() => {
        this.$refs.editDetail.edit(item);
      }, 500);
    },
    editChangeCurrent(id) {
      this.current = id;
    },
  },
};
</script>

<style scoped>
.index {
  display: flex;
  flex: 1;
}
.chat-list {
  width: 253px;
  user-select: none;
  cursor: pointer;
  height: 100%;
  background: #ffffff;
}
.chat-list .chat-list-user {
  height: 100%;
  /* height: 473px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.nav-title {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  letter-spacing: 2px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-list-user .user-info {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.empty {
  font-size: 14px;
  color: #ff6632;
}
.user-info:hover {
  background: #f5f7fa;
}
.expression-steps {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: #313233;
}
.expression-steps p:nth-child(1) {
  margin-right: 8px;
}
.expression-steps p:nth-child(2) {
  font-size: 14px;
  color: #7b7d80;
}
.active-background {
  height: 57px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f5f7fa;
  padding: 0 12px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
}
</style>
<template>
  <div class="tinymce-container editor-container">
    <textarea class="tinymce-textarea"
              :id="tinymceId"></textarea>
  </div>
</template>

<script>
import '../../../public/static/tinymce4.7.5/langs/zh_CN'
import api from "@/api/api"
import axios from "axios"
const plugins = [
  `advlist anchor autolink autosave code codesample colorpicker colorpicker
  contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime
  legacyoutput link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace
  spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount`
];
const toolbar = [
  `bold italic underline strikethrough alignleft aligncenter
  alignright outdent indent  blockquote undo redo removeformat`,
  `hr bullist numlist link image charmap	 preview anchor pagebreak
    fullscreen insertdatetime media table forecolor`
];
export default {
  name: 'tinymce',
  components: {},
  props: {
    id: {
      type: String
    },
    mchtId: {
      type: Number
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    menubar: {
      default: 'file edit insert view format table'
    },
    height: {
      type: Number,
      required: false,
      default: 400
    },
    width: {
      type: Number,
      required: false,
      default: 740
    }
  },
  data () {
    return {
      ossForm:{}, //上传图片的值
      host:'', //上传的地址
      hasChange: false,
      hasInit: false,
      tinymceId: this.id || 'vue-tinymce-' + +new Date()
    }
  },
  watch: {
    value (val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() => window.tinymce.get(this.tinymceId).setContent(val))
      }
    }
  },
  mounted () {
    this.initTinymce()
  },
  activated () {
    this.initTinymce()
  },
  deactivated () {
    this.destroyTinymce()
  },
  methods: {
    initTinymce () {
      const _this = this
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        width: this.width,
        height: this.height,
        language: 'zh_CN',
        body_class: 'panel-body ',
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: false,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        init_instance_callback: editor => {
          if (_this.value) {
            editor.setContent(_this.value)
          }
          _this.hasInit = true
          editor.on('NodeChange Change KeyUp SetContent', () => {
            this.hasChange = true
            this.$emit('input', editor.getContent())
          })
        },
        // 上传图片 要单独写出来一个方法
                images_upload_handler: (blobInfo, success, failure) => {
                    const formData = new FormData();
                   
                    console.log("======",blobInfo.blob())
                     api.upload
                     .policy()
                     .then((res) => {
                        this.host = res.result.host;
                        this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
                        this.ossForm.callback = res.result.callback;
                        this.ossForm.key = res.result.dir + "/" + blobInfo.blob().lastModified + blobInfo.blob().name;
                        this.ossForm.policy = res.result.policy;
                        this.ossForm.signature = res.result.signature;
                        this.ossForm.success_action_status = 200;
                        console.log("输出",this.host,this.ossForm)
                        formData.append('OSSAccessKeyId', this.ossForm.OSSAccessKeyId);
                        formData.append('callback', this.ossForm.callback);
                        formData.append('key', this.ossForm.key);
                        formData.append('policy', this.ossForm.policy);
                        formData.append('signature', this.ossForm.signature);
                        formData.append('success_action_status', 200);
                        formData.append('file', blobInfo.blob());
                        axios.post(this.host,formData).then(res=>{
                           console.log("=================AAAA==",res)
                           if(res.data.code==200){
                             let file = res.data.result;
                              success(file.filename);
                              return
                           }
                           failure('上传失败')
                        })
                       })
                       .catch((err) => {
                          failure('上传出错')
                      });
                    // uploadFile(formData).then(res => {
                    //     if (res.data.code == 0) {
                    //         let file = res.data.data;
                    //         success(file.filePath);
                    //         return
                    //     }
                    //     failure('上传失败')
                    // }).catch(() => {
                    //     failure('上传出错')
                    // })
                }
      })
    },
    destroyTinymce () {
      if (window.tinymce.get(this.tinymceId)) {
        window.tinymce.get(this.tinymceId).destroy()
      }
    },
    setContent (value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent () {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK (arr) {
      const _this = this
      arr.forEach(v => {
        window.tinymce.get(_this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      })
    }
  },
  destroyed () {
    this.destroyTinymce()
  }
}
</script>

<style scoped>
.tinymce-container {
  position: relative;
}

.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 10px;
  top: 2px;
  /*z-index: 2005;*/
}

.editor-upload-btn {
  display: inline-block;
}
</style>

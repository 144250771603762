<template>
  <div class="chat-window">
    <div class="chat-title">
      <p>已发稿件</p>
      <p>{{total}}</p>
    </div>
    <div class="chat-container">
      <div class="table-list">
        <el-table :data="tableData"
                  style="width: 100%"
                  max-height="470"
                  @selection-change="handleSelectionChange"
                  border>
          <el-table-column type="selection"
                           align="center"
                           width="40"></el-table-column>
          <el-table-column label="标题"
                           align="center"
                           width="250px"
                           prop="title">
            <template slot-scope="scope">{{scope.row.title}}</template>
          </el-table-column>
          <el-table-column label="时间"
                           align="center"
                           prop="time">
            <template slot-scope="scope">{{scope.row.createTime}}</template>
          </el-table-column>
          <el-table-column label="状态"
                           width="80px"
                           align="center">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="text"
                         @click="handleEdit(scope.row)">修改</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            没有稿件
          </div>
        </el-table>
      </div>
      <div v-if="tableData.length > 0"
           class="submit">
        <el-button type="primary"
                   round
                   size="small"
                   style="background:#4db4d4;"
                   @click="handleDelete">删除所选</el-button>
        <div class="pagination-container">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         layout="total,prev, pager, next"
                         :page-size="listQuery.pageSize"
                         :current-page.sync="listQuery.pageNum"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api' //文章的接口
export default {
  // 已发稿件
  name: 'alreadySentManuscript',
  components: {

  },
  data () {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
      },
      model: {},
      multipleSelection: [],
      tableData: [],
      selectArrStr:'' , //选中的值变成以逗号隔开的字符串
    }
  },
  created () {
     this.getArticleList() //获取文章列表 已发布
  },
  methods: {
    // 修改
    handleEdit (item) {
       api.article.articleDetail(item.id).then(res=>{
         console.log(res.result)
         item.content=res.result.content
         item.fromId=2
         this.$emit('editArticl',JSON.stringify(item))
       })
    },
     //垃圾箱列表的数值
    getRubbishList(){
      api.article.rubbishList({pageNo:this.listQuery.pageNum,pageSize:this.listQuery.pageSize}).then(res=>{
        this.$emit('dustbinUpdateTotal', res.result.total);
      }).catch(err=>{
        console.log(err)
      })
      
    },
    // 删除所选
    handleDelete () {
      //  console.log(this.multipleSelection)
       let arr = []
       if(this.multipleSelection.length>0){
         this.multipleSelection.forEach(item=>{
             arr.push(item.id.toString())
         })
        this.selectArrStr = arr.join(",")
        console.log(this.selectArrStr)
       }
      api.article.deleteArticle({id:this.selectArrStr}).then(res=>{
        this.$message({
          message: '删除成功',
          type: 'success'
        });
         this.getRubbishList() //更新垃圾箱数据
         this.getArticleList() 
        
      })
    },
    // 表格选择
    handleSelectionChange (val) {
      this.multipleSelection = val
      // console.log("===触发了吗",val)
    },

    // 每页页数变化
    handleSizeChange (val) {
      console.log(val)
    },
    // 当前页变化  切换页码
    handleCurrentChange (val) {
    // console.log("===",val)
    this.listQuery.pageNum = val
    this.getArticleList()
    },
    //获取文章列表
    getArticleList(){
      // status 0 草稿 1 发布
      api.article.articleList({status:1,pageNo:this.listQuery.pageNum,pageSize:this.listQuery.pageSize}).then(res=>{
        // console.log("文章列表",res.result.total)
        this.total=res.result.total
        this.tableData = res.result.records
        this.$emit('alreadyUpdateTotal',this.total)
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
  position: relative;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-title p:nth-child(2) {
  font-size: 18px;
  color: #7b7d80;
  margin-left: 8px;
}
.chat-window .chat-container {
  height: 100%;
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0; */
  border-top: 1px solid #dcdfe6;
}
.submit button {
  width: 84px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
<template>
  <div class="chat-window">
    <div class="chat-title">编辑</div>
    <div class="chat-container">
      <el-form
        :model="model"
        label-position="top"
        size="small"
        class="content"
      >
        <el-form-item label="蜘客标题">
          <el-input
            v-model="model.title"
            class="input-style"
            placeholder="请输入标题……"
          ></el-input>
        </el-form-item>
        <el-form-item label="蜘客文章图片封面">
          <!-- <div class="uploadExplain">
            PNG 格式，180 × 180像素，大小不超过500kb
          </div> -->
          <div class="uploadExplain">
            180 × 180像素，大小不超过500kb
          </div>
          <!-- 上传图片 -->
          <el-upload
            class="avatar-uploader"
            :action="host"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :data="ossForm"
            :multiple="false"
          >
            <img
              v-if="imageUrl"
              :src="imageUrl"
              class="avatar"
            >
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="正文" class="titleTopBox">
          <div id="fwb">

          </div>
          <!-- <tinymce
            class="tinymce_info"
            v-model="model.content"
            ref="content"
          ></tinymce> -->
        </el-form-item>
      </el-form>
      <div class="submit">
        <div class="submit-button">
          <el-button
            type="primary"
            round
            size="small"
            @click="handleSubmit(1)"
            style="background:#4db4d4;"
          >保存发布</el-button>
          <el-button
            round
            size="small"
            @click="handleSubmit(0)"
          >存草稿箱</el-button>

        </div>
        <div class="submit-remark">
          <p>1、稿件发布至蜘信手机端蜘客空间。</p>
          <p>2、已发稿件自动保存至电脑版“已发稿件“</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import api from "@/api/api";
import E from 'wangeditor'
import axios from "axios"
export default {
  // 编辑
  name: "editDetail",
  components: {
    Tinymce,
  },
  data() {
    return {
      model: {
        title: "", //标题 必填
        content: "", //内容
        status: null, //状态 1发布 0草稿
        editType: 1, //编辑类型 0手机端 1电脑端
        headImageUrl: "", //封面 必填
      },
      imageUrl: "",
      ossForm: {}, //oss配置
      host: "", //上传地址
      saveStatus: 0, //设置一个值 0原本的保存 1已发稿件修改的保存 草稿箱的编辑
      id: "", //修改编辑的id
      fromId:'',//来自那个修改
      editor:{},//富文本对象
    };
  },
  created() {
  },
  mounted(){
    this.initWangeditor()
  },
  methods: {
    //初始化wangeditor富文本
    initWangeditor(){
    this.editor = new E( document.getElementById('fwb') )
    this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      resultFiles.forEach(item=>{
         api.upload
          .policy()
          .then((res) => {
            let host = res.result.host;
            let OSSAccessKeyId = res.result.accessKeyId;
            let callback = res.result.callback;
            let key = res.result.dir + "/" + item.lastModified + item.name;
            let policy = res.result.policy;
            let signature = res.result.signature;
            let success_action_status = 200;
            let formData = new FormData();
            formData.append('OSSAccessKeyId', OSSAccessKeyId);
            formData.append('callback', callback);
            formData.append('key', key);
            formData.append('policy', policy);
            formData.append('signature', signature);
            formData.append('success_action_status', success_action_status);
            formData.append('file', item);
            axios.post(host,formData).then(ress=>{
              console.log("图片===",ress)
              insertImgFn(ress.data.result.filename)
            })
          })
          .catch((err) => {
            console.log(err);
          });
      })
      
    // resultFiles 是 input 中选中的文件列表
    // insertImgFn 是获取图片 url 后，插入到编辑器的方法

    // 上传图片，返回结果，将图片插入到编辑器中
    // insertImgFn(imgUrl)
}
    this.editor.create()
    },
    // 确定 发布文章
    handleSubmit(id) {
      //id 0草稿箱 1发布
      this.model.status = id;
      if (this.model.title == "") {
        this.$message({
          message: "请填写文章标题",
          type: "warning",
        });
        return;
      }
      if (this.model.headImageUrl == "") {
        this.$message({
          message: "请先上传封面图",
          type: "warning",
        });
        return;
      }
      if (this.saveStatus == 0) {
        this.model.content = this.editor.txt.html();
        api.article.release(this.model).then((res) => {
          if (id == 0) {
            this.$message({
              message: "已保存到草稿箱",
              type: "success",
            });
          } else {
            this.$message({
              message: "发布成功",
              type: "success",
            });
            
          }
          this.getArticleList();
          this.clearModel();
          this.imageUrl = "";
        });
      }
      this.model.content = this.editor.txt.html();
      if (this.saveStatus == 1) {
        api.article.editArticle(this.id, this.model).then((res) => {
         
            console.log(res);
            if (id == 0) {
              this.$message({
                message: "已保存到草稿箱",
                type: "success",
              });
              
            } else {
              this.$message({
                message: "发布成功",
                type: "success",
              });
             
            }
            this.getArticleList();
            this.clearModel();
            this.imageUrl = "";
        });
      }
    },
    //上传图片成功后
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log("====",res.result)
      this.model.headImageUrl = res.result.filename;
    },
    //上传图片前
    beforeAvatarUpload(file) {
      //限制是png和500kb
      // const isPng = file.type === "image/png";
      const isSize = (file.size / 1024 / 1024 / 1024) * 1000000 < 500;
      // if (!isPng) {
      //   this.$message.error("上传头像图片只能是 png 格式!");
      //   return;
      // }
      if (!isSize) {
        this.$message.error("上传头像图片大小不能超过 500kb!");
        return;
      }
      //promise 构造oss参数
      return new Promise((resolve, reject) => {
        api.upload
          .policy()
          .then((res) => {
            this.host = res.result.host;
            this.ossForm.OSSAccessKeyId = res.result.accessKeyId;
            this.ossForm.callback = res.result.callback;
            this.ossForm.key = res.result.dir + "/" + file.uid + file.name;
            this.ossForm.policy = res.result.policy;
            this.ossForm.signature = res.result.signature;
            this.ossForm.success_action_status = 200;
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    //清空model表单
    clearModel() {
      this.model.title = "";
      this.model.content = "";
      this.model.title = "";
      this.model.status = null;
      this.model.editType = 1;
      this.model.headImageUrl = "";
      // this.$refs.content.setContent("");
      this.editor.txt.clear()
    },
    //初始化更新数量
    getArticleList() {
      // status 0 草稿 1 发布
      api.article
        .articleList({ status: 1 })
        .then((res) => {
          this.$emit("alreadyUpdateTotal", res.result.total);
          setTimeout(()=>{
            if(this.fromId==1){
            this.$emit("editChangeCurrent",1);
          }
          if(this.fromId==2){
            this.$emit("editChangeCurrent",2);
          }
          },500)
        })
        .catch((err) => {
          console.log(err);
        });
      // status 0 草稿 1 发布
      api.article
        .articleList({ status: 0 })
        .then((res) => {
          this.$emit("draftsUpdateTotal", res.result.total);
          setTimeout(()=>{
            if(this.fromId==1){
            this.$emit("editChangeCurrent",1);
          }
          if(this.fromId==2){
            this.$emit("editChangeCurrent",2);
          }
          },500)
        })
        .catch((err) => {
          console.log(err);
        });
        
    },
    //修改文章
    edit(item) { 
      let editMsg = JSON.parse(item);
      this.model.title = editMsg.title;
      this.model.status = null;
      this.model.editType = 1;
      this.model.headImageUrl = editMsg.headImageUrl;
      this.imageUrl = editMsg.headImageUrl;
      // this.$refs.content.setContent(editMsg.content);
      this.editor.txt.html(editMsg.content)
      console.log("======修改", editMsg.content);
      this.saveStatus = 1;
      this.id = editMsg.id;
      this.fromId = editMsg.fromId
      this.model.content = this.editor.txt.html();
    },
    //获取oss上传成功的回调
    // getOssCallBack(){
    //   ossApi.callback().then(res=>{
    //     console.log(res)
    //   })
    // },
  },
};
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
  position: relative;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  position: relative;
}
.chat-window .chat-container {
  height: calc(100% - 128px);
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  /* border-bottom: 1px solid #dcdfe6; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.chat-container .content {
  width: 100%;
  /* height: 472px; */
  padding: 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}
.content .el-form-item:nth-child(1) {
  padding-left: 20px;
}
.content .el-form-item:nth-child(2) {
  margin-bottom: 0;
}
.content .el-form-item:nth-child(2) >>> .el-form-item__label {
  margin-left: 20px;
}
.tinymce_info {
  height: 300px !important;
}
.tinymce-container >>> .mce-tinymce {
  width: 100% !important;
  height: 100% !important;
}

.el-form--label-top >>> .el-form-item__label {
  padding: 0;
  font-size: 16px;
  color: #313233;
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  z-index: 99;
  background: #ffffff;
  border-top: 1px solid #dcdfe6;
}
.submit-button {
  display: flex;
  flex-direction: row;
}
.submit button {
  width: 84px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit-remark {
  font-size: 12px;
  color: #7b7d80;
}
.submit-remark p:nth-child(1) {
  margin-bottom: 4px;
}
.input-style /deep/ input {
  border: none !important;
  padding-left: 0;
}
.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 20px;
}
.avatar-uploader /deep/ .el-upload:hover {
  border-color: #409eff;
  margin-left: 20px;
  margin-top: 20px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
.uploadExplain {
  font-size: 12px;
  color: #999;
  margin-left: 20px;
  margin-top: -10px;
}
.titleTopBox /deep/ .el-form-item__label{
  padding-left: 20px;
}
.titleTopBox /deep/ .el-form-item__content{
  padding: 0 20px;
}
</style>
<template>
  <div class="chat-window">
    <div class="chat-title">
      <p>垃圾箱</p>
      <p>{{total}}</p>
    </div>
    <div class="chat-container">
      <div class="table-list">
        <el-table :data="tableData"
                  style="width: 100%"
                  @selection-change="handleSelectionChange"
                  border>
          <el-table-column type="selection"
                           align="center"
                           width="40"></el-table-column>
          <el-table-column label="标题"
                           align="center"
                           width="250px"
                           prop="title">
            <template slot-scope="scope">{{scope.row.title}}</template>
          </el-table-column>
          <el-table-column label="时间"
                           align="center"
                           prop="time">
            <template slot-scope="scope">{{scope.row.createTime}}</template>
          </el-table-column>
          <el-table-column label="状态"
                           width="80px"
                           align="center">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="text"
                         @click="handleRestore(scope.row)">还原</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            没有稿件
          </div>
        </el-table>
      </div>
      <div v-if="tableData.length > 0"
           class="submit">
        <el-button type="primary"
                   round
                   size="small"
                   style="background:#4db4d4;"
                   @click="handleDelete">删除所选</el-button>
        <div class="pagination-container">
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         layout="total,prev, pager, next"
                         :page-size="listQuery.pageSize"
                         :current-page.sync="listQuery.pageNum"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api' //文章的接口
export default {
  // 草稿箱
  name: 'dustbinDetail',
  components: {

  },
  props: {
    clearStatus:{
      type:Number,
      default:0
    }
  },
  data () {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
      },
      model: {},
      multipleSelection: [],
      tableData: [] ,
    }
  },
  watch:{
      clearStatus(newVal,oldVal){
         this.getList()
      }
  },
  created () {
     this.getList()
  },
  methods: {
    // 垃圾箱列表
    getList(){
      api.article.rubbishList({pageNo:this.listQuery.pageNum,pageSize:this.listQuery.pageSize}).then(res=>{
        this.total=res.result.total
        this.tableData = res.result.records
        // console.log("垃圾箱列表======",res.result.records)
        this.$emit('dustbinUpdateTotal', res.result.total);
      }).catch(err=>{
        console.log(err)
      })
      
    },
    //获取草稿和已发布的列表 为了获取数量
    getArticleList(){
      // status 0 草稿 1 发布
      //草稿
      api.article.articleList({status:0}).then(res=>{
        // console.log("草稿列表",res.result.total)
        this.$emit('draftsUpdateTotal', res.result.total);
      }).catch(err=>{
        console.log(err)
      })
       //发布 已发
      api.article.articleList({status:1}).then(res=>{
        // console.log("发布列表 已发",res.result.total)
        this.$emit('alreadyUpdateTotal', res.result.total);
      }).catch(err=>{
        console.log(err)
      })
    },
    // 还原
    handleRestore (val) {
      api.article.rubbishReturn({id:val.id}).then(res=>{
        if(res.code==200){
          this.$message({
          message: '还原成功',
          type: 'success'
        });
        this.getArticleList()
        this.getList()
        }else{
          this.$message.error(res.message);
        }
         
      })
    },
    // 删除所选
    handleDelete () {
         let arr = []
       if(this.multipleSelection.length>0){
         this.multipleSelection.forEach(item=>{
             arr.push(item.id.toString())
         })
       }
       arr.forEach(item=>{
         api.article.completelyDelete(item).then(res=>{
              this.$message({
              message: '删除成功',
              type: 'success'
        });
        this.getList()
         })
       })      
    },
    //清空垃圾箱
    // clearEmpty(){
    //   api.article.clearRubbish().then(res=>{
    //       this.$message({
    //           message: '清空成功',
    //           type: 'success'
    //     });
    //     this.getList()
    //   })
    // },
    // 表格选择
    handleSelectionChange (val) {
      this.multipleSelection = val
    },

    // 每页页数变化
    handleSizeChange () {

    },
    // 当前页变化  切换页码
    handleCurrentChange () {
     this.listQuery.pageNum = val
     this.getList()
    },
  }
}
</script>

<style scoped>
.chat-window {
  min-width: 506px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #dcdfe6;
  position: relative;
}
.chat-window .chat-title {
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 18px;
  color: #313233;
  letter-spacing: 2px;
  padding: 0 20px;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
}
.chat-window .chat-title p:nth-child(2) {
  font-size: 18px;
  color: #7b7d80;
  margin-left: 8px;
}
.chat-window .chat-container {
  min-height: 540px;
  height: 100%;
  width: 100%;
  /* padding-top: 20px; */
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* position: relative; */
}
.submit {
  width: 100%;
  height: 68px;
  padding-left: 10px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: absolute;
  bottom: 0; */
  border-top: 1px solid #dcdfe6;
}
.submit button {
  width: 84px;
  height: 28px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>